class ReqAnimFrameService {
	private static _instance: ReqAnimFrameService;

	static get Instance() {
		return this._instance || (this._instance = new this());
	}

	reqAnimFrame =
		requestAnimationFrame ||
		//@ts-ignore
		// eslint-disable-next-line no-undef
		webkitRequestAnimationFrame ||
		// eslint-disable-next-line no-undef
		mozRequestAnimationFrame ||
		function (callback) {
			setTimeout(callback, 1000 / 60);
		};

	// eslint-disable-next-line no-undef
	cancAnimFrame = cancelAnimationFrame || mozCancelAnimationFrame;
}

export default ReqAnimFrameService.Instance;
