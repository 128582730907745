class BodyClasses {
	private body: HTMLBodyElement | null;

	protected static _instance: BodyClasses;
	constructor() {
		this.body = document.querySelector('body');
		$Y.watch({
			'DUNHILL::HeaderVisible': () => {
				if (this.body) this.body.classList.add('header-visible');
			}
		});
		$Y.watch({
			'DUNHILL::HeaderHidden': () => {
				if (this.body) this.body.classList.remove('header-visible');
			}
		});
	}
}

export default BodyClasses;
