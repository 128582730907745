class yTosOverrides {
	private static _instance: yTosOverrides;

	constructor() {
		this.init();
	}

	static get Instance(): yTosOverrides {
		return this._instance || (this._instance = new this());
	}

	private _overrideHelperFunction(helper: any, data: any): void {
		if (typeof helper != 'function') {
			return;
		}
		helper.prototype[data.helperFunction] = data.newFunction;
	}

	private _overrideHelper(data: any): void {
		let helperParsedWatchName = data.helperCategory[0].toLowerCase() + data.helperCategory.substring(1);
		helperParsedWatchName += '.' + data.helperName[0].toLowerCase() + data.helperName.substring(1);

		if (
			typeof $Y.helpers[data.helperCategory] !== 'undefined' &&
			typeof $Y.helpers[data.helperCategory][data.helperName] !== 'undefined'
		) {
			this._overrideHelperFunction($Y.helpers[data.helperCategory][data.helperName], data);
			if (!data.isWatchOne) {
				$Y.watch({
					[`yTos::HelperParsed::${helperParsedWatchName}`]: (helper: any) => this._overrideHelperFunction(helper, data)
				});
			}
		} else {
			if (data.isWatchOne) {
				$Y.watchOne({
					[`yTos::HelperParsed::${helperParsedWatchName}`]: (helper: any) => this._overrideHelperFunction(helper, data)
				});
			} else {
				$Y.watch({
					[`yTos::HelperParsed::${helperParsedWatchName}`]: (helper: any) => this._overrideHelperFunction(helper, data)
				});
			}
		}
	}

	init(): void {
		$Y.watch({
			'DUNHILL::OverrideHelper': (data: any) => this._overrideHelper(data)
		});
	}
}

export default yTosOverrides;
