import { yTosEvents } from '../constants';

class TrackingManager {
	public static _instance: TrackingManager;
	public static TRACKING_EVENT: string = yTosEvents.TrackEvents();
	public static TRACKING_TYPE_GA: string = 'ga';
	public static TRACKING_TYPE_TC: string = 'tc';

	public eventsForTc: Array<any> = [];

	constructor() {
		this._init();
	}

	private _init() {
		this.watchTrackingEvents();
	}

	/**
	 * Default Tracking Method
	 * @function    trackGAEvent
	 * @description Track event using Google Analytics -> yTos.tracking.trackEvent(data)
	 * @param  {} {data}
	 */
	private trackGAEvent = (data: any) => {
		if (typeof yTos.tracking.trackEvent !== 'function') {
			console.log('yTos.tracking.trackEvent undefined !');
			return;
		}

		yTos.tracking.trackEvent(data);
	};

	/**
	 * @function    trackTcEvent
	 * @description Track event using tagcommander -> window.tc_events_19
	 * @param  		{} context
	 * @param  		{} name
	 * @param  		{} data
	 */
	private trackTcEvent = ({ context, name, data }: { context: string; name: string; data: string }) => {
		if (typeof window.tc_events_19 !== 'function') {
			this.eventsForTc.push({ context, name, data });
			return;
		}

		if (!context || !name) return;
		window.tc_events_19(context, name, data);
	};

	/**
	 *  $Y.Watch Tracking Events
	 *  watch for tracking notify -> TrackingManager.TRACKING_EVENT
	 * */
	private watchTrackingEvents(): void {
		$Y.watch({
			[TrackingManager.TRACKING_EVENT]: (payload: any) => {
				if (!payload) return;

				let trackingMethod;
				//choose tracking method
				//using switch so we can add more tracking methods
				switch (payload.type) {
					case TrackingManager.TRACKING_TYPE_TC:
						trackingMethod = this.trackTcEvent;
						break;
					case TrackingManager.TRACKING_TYPE_GA:
						trackingMethod = this.trackGAEvent;
						break;
					default:
						trackingMethod = yTos.tracking.trackEvent;
						break;
				}

				trackingMethod(payload);
			},
			[yTosEvents.TrackProductData()]: (payload: any) => {
				if (!payload) {
					console.log('You must send a payload to call TrackProductData tracking event');
					return;
				}

				yTos.tracking.trackProductData(payload);
			}
		});

		window.addEventListener(
			'TC::tc_events_19_ready',
			() => {
				if (typeof window.tc_events_19 === 'function') {
					this.eventsForTc = this.eventsForTc.filter((e) => {
						this.trackTcEvent(e);
						return false;
					});
				} else {
					console.log('tc_events_19 undefined again !');
				}
			},
			false
		);
	}
}

export default TrackingManager;
