import aa from 'search-insights';

class AlgoliaTrackingManager {
	isAlgoliaEnabled = window.jsInit_Algolia?.enabled ?? false;
	appId = window.jsInit_Algolia?.appId ?? false;
	apiKey = window.jsInit_Algolia?.searchApiKey ?? false;

	constructor() {
		this._init();
	}

	private setEvents() {
		$Y.watch({
			'yTos::Item::ItemAddedToShoppingBag': (data: any) => {
				this.sendAddToBagEvent(data.itemData.code10);
			}
		});
	}

	private sendAddToBagEvent(code10: string) {
		aa('convertedObjectIDs', {
			userToken: window.jsInit_Algolia.userToken, // required for Node.js
			index: window.jsInit_Algolia.index,
			eventName: 'Product Added To Bag',
			objectIDs: [code10]
		});
	}

	private initAlgoliaInsights() {
		aa('init', {
			appId: this.appId,
			apiKey: this.apiKey
		});
	}

	private _init() {
		if (this.isAlgoliaEnabled) {
			this.initAlgoliaObject();
			this.initAlgoliaInsights();
			this.setEvents();
		}
	}

	private initAlgoliaObject() {
		const userId = window.tc_vars.user_externalidentifier ?? '';
		const userSessionId = yTos.getSessionStorageId().split('_')[1] ?? 'user';

		const userToken = userId === '' ? `user-${userSessionId}` : `user-${userId}`;

		window.jsInit_Algolia.userToken = userToken;
	}
}

export default AlgoliaTrackingManager;
