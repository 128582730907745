/* eslint-disable prettier/prettier */
import Ajax from './ajax';

class NewsletterManager {
	constructor() {
		this.init();
	}

	private _openNewsletterLayer(isFirstVisit: boolean) {
		const firstVisitClass = isFirstVisit ? 'firstVisit' : 'notFirstVisit';

		Ajax.generateView('WebComponentView', 'RenderNewsletterLayer', { async: true }).then((response: any) => {
			const magnificOptions = {
				cssClasses: ['newsletterLayer', 'compressed-flexible', firstVisitClass],
				content: response.response
			};

				$Y.notify('yTos::OpenLayer', magnificOptions);

				$Y.cookie.set('hasSeenNewsletterLayer', true, 31536000);
			});
	}

	private _openLayerOnFirstVisit() {
		const shouldOpenNewsletterLayer = !$Y.cookie.get('hasSeenNewsletterLayer');

		if (shouldOpenNewsletterLayer) {
			this._openNewsletterLayer(true);
		}

	}

	private _setEvents() {
			$Y.watch({
				'DUNHILL::OpenNewsletterLayer': () => this._openNewsletterLayer(false),
				'yTos::HelpersFinishedLoading': () => this._openLayerOnFirstVisit()
			});

		
	}

	init() {
			this._setEvents();
		
	}
}

export default NewsletterManager;
