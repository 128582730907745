// @ts-nocheck
import { array } from 'yargs';
import { dunhillDebug } from './_debug';

/* eslint-disable prefer-template */

/**
 * loadPage
 *
 * @method
 * @description Load ControllerName script
 * @returns {Promise}
 */

export const loadSite = () => {
	return new Promise((resolve) => {
		import(
			/* webpackInclude: /\.ts$/ */
			/* webpackChunkName: "scripts/site" */
			'FRONTEND_DEV/common/scripts/site'
		)
			.then((module) => {
				resolve(module);
			})
			.catch((error) => {
				dunhillDebug('error', error);
				resolve();
			});
	});
};

export const loadPage = () => {
	const page = yTos.Dunhill.controllerName.toLowerCase();

	return new Promise((resolve) => {
		import(
			/* webpackInclude: /\.ts$/ */
			/* webpackChunkName: "pages/[request]" */
			`FRONTEND_DEV/pages/${page}/${page}`
		)
			.then((module) => {
				resolve(module);
			})
			.catch((error) => {
				dunhillDebug('warn', error);
				resolve();
			});
	});
};

/**
 * loadWebComponents
 *
 * @method
 * @description Dinamically load all web-components js.
 * @param {Array.<string>} components
 * @param {boolean} shouldLoadOnlyExisting
 * @returns {Promise}
 */
export const loadWebComponents = (components, shouldLoadOnlyExisting = false) => {
	const loadedComponents = yTos.Dunhill.componentsList.length;
	const componentsToLoad = components
		? components.filter((x) => {
				if (!shouldLoadOnlyExisting) return yTos.Dunhill.componentsList.indexOf(x) === -1;
				return yTos.Dunhill.componentsList.indexOf(x) === -1 && document.querySelector(x) !== null;
		  })
		: yTos.Dunhill.componentsList?.splice(0, loadedComponents);

	return new Promise((resolve) => {
		Promise.all(
			componentsToLoad.map((webComponent) => {
				yTos.Dunhill.componentsList.push(webComponent);
				return import(
					/* webpackInclude: /\.ts$/ */
					/* webpackChunkName: "web-components/[request]" */
					`FRONTEND_DEV/web-components/${webComponent}/${webComponent}`
					// `../../../web-components/${webComponent}/${webComponent}`
					// due to a small bug we can't transpile template literals inside dynamic import
					// https://github.com/babel/babel/issues/9753
				);
			})
		)
			.then(() => {
				resolve();
			})
			.catch((error) => {
				dunhillDebug('warn', error);
				resolve();
			});
	});
};

export const initWebComponentMutationObserve = (observeSelector: string = '.contentWrapper') => {
	const targetNode = document.querySelector(observeSelector);

	// Options for the observer (which mutations to observe)
	const config = { attributes: false, childList: true, subtree: true };

	// Callback function to execute when mutations are observed
	const callback: MutationCallback = (mutationList) => {
		for (const mutation of mutationList) {
			if (mutation.addedNodes.length > 0) {
				const nodes = Array.from(mutation.addedNodes);
				loadComponentsForHtml(nodes);
			}
		}
	};

	// Create an observer instance linked to the callback function
	const observer = new MutationObserver(callback);

	// Start observing the target node for configured mutations
	observer.observe(targetNode, config);
};

const loadComponentsForHtml = (selectedNodes) => {
	var nodes = Array.from(selectedNodes);
	const fakeWrapper: HTMLDivElement = document.createElement('div');
	const clonedNodes = nodes.map((node) => {
		var clonedNode = node.cloneNode(true);
		return clonedNode;
	});

	fakeWrapper.append(...clonedNodes);
	let loadedWebComponents: NodeListOf<HTMLElement> = fakeWrapper.querySelectorAll('[data-element]');
	if (loadedWebComponents.length > 0) {
		let aArr: string[] = [];
		loadedWebComponents.forEach((webComponent: HTMLElement) => {
			let componentName: string = webComponent.getAttribute('data-element');
			if ($Y.Dunhill.componentsList.includes(componentName) || aArr.includes(componentName)) return;
			aArr.push(componentName);
		});
		if (aArr.length > 0) {
			loadWebComponents(aArr, false);
		}
	}
};

export const initWatches = () => {
	$Y.watch({
		'DUNHILL::RefreshPageComponents': (tagetElement: any) => {
			loadComponentsForHtml(tagetElement);
		},
		'DUNHILL::LoadWebComponents': (componentsList: string[]) => {
			loadWebComponents(componentsList);
		},
		'DUNHILL::ObserveForWebComponents': (observeSelector: string) => {
			initWebComponentMutationObserve(observeSelector);
		}
	});
};
