import { Resolver } from 'webpack';

class VideoManager {
	private static _instance: VideoManager;

	static get Instance() {
		return this._instance || (this._instance = new this());
	}

	/**
	 * This function check if the brightcove script is already present in the DOM
	 * @returns true if it is present, false if it is not
	 */
	brightcoveExists(): boolean {
		return this._brightcoveExists;
	}

	private _brightcoveExists: boolean = false;
	private _initPromise: Promise<void>;

	async init(): Promise<void> {
		if (this._initPromise != null) {
			return this._initPromise;
		}
		this._initPromise = new Promise(async (resolve, reject) => {
			let brightcoveParams = '4719076374001/Ez1hldIfY_default';
			if (window.jsInit && typeof window.jsInit.brightcove !== 'undefined') {
				brightcoveParams =
					window.jsInit.brightcove.account +
					'/' +
					window.jsInit.brightcove.player +
					'_' +
					window.jsInit.brightcove.embed;
			} else {
				console.warn('jsInit not found, fallback on playerId Ez1hldIfY');
			}
			const urlLib: string = 'https://players.brightcove.net/' + brightcoveParams + '/index.min.js';

			// Add and execute the player script tag
			let scriptDOM = document.createElement('script');
			scriptDOM.id = 'brightcove';
			scriptDOM.setAttribute('data-src', urlLib);
			document.body.prepend(scriptDOM);

			try {
				await fetch(urlLib)
					.then((response) => response.text())
					.then((html) => {
						scriptDOM.prepend(html.replace('define.amd', 'define.bad'));
						this._brightcoveExists = true;
						resolve();
					});
			} catch (e) {
				reject(new Error('player script could not be downloaded'));
			}
		});

		await this._initPromise;
	}

	public getPlayerStyles(playerId: string) {
		const bcVideoJsStyle = document.querySelector('#bc-style-vjs') as HTMLElement;
		const bcPlayerStyle = document.querySelector(`.bc-style-${playerId}-default`) as HTMLElement;

		return { bcVideoJsStyle, bcPlayerStyle };
	}
}

export default VideoManager.Instance;
