class FormsManager {
	constructor() {
		$Y.watch({
			'yTos::HelperParsed::ui.ajaxForm': () => $Y.debounce(this.validateFormFields),
			'yTos::HelpersFinishedLoading': () => $Y.debounce(this.validateFormFields)
		});
	}

	public validateFormFields() {
		const fields = document.querySelectorAll('form input, form select, form textarea');
		fields.forEach((field: any) => {
			if (field.value !== '') {
				yTos.helpers.UI.AjaxForm.validateInput($(field));
			}
		});
	}
}

export default FormsManager;
