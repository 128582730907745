/**
     _____ ______   ________  ________  _______           ________      ___    ___ 
    |\   _ \  _   \|\   __  \|\   ___ \|\  ___ \         |\   __  \    |\  \  /  /|
    \ \  \\\__\ \  \ \  \|\  \ \  \_|\ \ \   __/|        \ \  \|\ /_   \ \  \/  / /
     \ \  \\|__| \  \ \   __  \ \  \ \\ \ \  \_|/__       \ \   __  \   \ \    / / 
      \ \  \    \ \  \ \  \ \  \ \  \_\\ \ \  \_|\ \       \ \  \|\  \   \/  /  /  
       \ \__\    \ \__\ \__\ \__\ \_______\ \_______\       \ \_______\__/  / /    
        \|__|     \|__|\|__|\|__|\|_______|\|_______|        \|_______|\___/ /     
                                                                      \|___|/                                                            
              ________  ________  ________  ________  ________  _______           _________  _______   ________  _____ ______      
             |\   __  \|\   __  \|\   ____\|\   __  \|\   ___ \|\  ___ \         |\___   ___\\  ___ \ |\   __  \|\   _ \  _   \    
             \ \  \|\  \ \  \|\  \ \  \___|\ \  \|\  \ \  \_|\ \ \   __/|        \|___ \  \_\ \   __/|\ \  \|\  \ \  \\\__\ \  \   
              \ \   __  \ \   _  _\ \  \    \ \   __  \ \  \ \\ \ \  \_|/__           \ \  \ \ \  \_|/_\ \   __  \ \  \\|__| \  \  
               \ \  \ \  \ \  \\  \\ \  \____\ \  \ \  \ \  \_\\ \ \  \_|\ \           \ \  \ \ \  \_|\ \ \  \ \  \ \  \    \ \  \ 
                \ \__\ \__\ \__\\ _\\ \_______\ \__\ \__\ \_______\ \_______\           \ \__\ \ \_______\ \__\ \__\ \__\    \ \__\
                 \|__|\|__|\|__|\|__|\|_______|\|__|\|__|\|_______|\|_______|            \|__|  \|_______|\|__|\|__|\|__|     \|__|

				                                         I blasfemi: Alessandro, Mimmo, David, Davide, Giorgio, Radoslaw, Sebastiano
	
	NB: Se ti viene in mente di cancellarlo, ti auguriamo di passare il resto dei tuoi giorni a risolvere bug randomici <3
*/

// @ts-nocheck
import Device from './utils/_devices';
import { loadSite, loadPage, loadWebComponents, initWatches } from './utils/_loadResources';
import fetchWrapper from './utils/_fetch';

import {
	TrackingManagerService,
	yTosOverridesService,
	MPulseService,
	PopupsService,
	BodyBlockService,
	BodyClassesService,
	NewsletterManagerService,
	BackgroundLayerManagerService,
	FormsManagerService,
	TcCookieManagerService,
	PwaManager,
	VerifyAgeMajorityService
} from './services';

/* vedi cartier */
new yTosOverridesService();

new Device();
yTos.fetch = fetchWrapper;

const init = async () => {
	//let pageInstance = null;

	initWatches();
	// CORE
	const yTosStart = await yTos.helpers.initSite();

	yTos.DEBUG = yTos.environment !== 'prod';

	new PwaManager();

	//Ytos.ready
	const initHelpers = await yTosStart.initHelpers;

	// VIA NOSTRO JS
	const [siteModule, pageModule] = await Promise.all([loadSite(), loadPage()]);

	if (typeof siteModule !== 'undefined') {
		const SiteClass = siteModule.default;
		new SiteClass();
	}
	if (typeof pageModule !== 'undefined') {
		try {
			const PageClass = pageModule.default;
			new PageClass();
		} catch (_) {
			console.warn('Page module not defined correctly');
		}
	}
	await loadWebComponents();

	// VIA TUTTO frontend F31
	yTos.ready = true;
	initHelpers();

	new PopupsService();
	new BodyClassesService();
	new BodyBlockService();
	new MPulseService();
	new NewsletterManagerService();
	new BackgroundLayerManagerService();
	new TrackingManagerService();
	new FormsManagerService();
	new TcCookieManagerService();
	new VerifyAgeMajorityService();

	yTos.notify('DUNHILL:SiteReady');
};

init();
