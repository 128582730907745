class BackgroundLayerManager {
	body: HTMLElement = document.body;
	backgroundLayer: HTMLElement | null;

	constructor() {
		this.init();
	}

	_addLayerToPageBody() {
		const layer = document.createElement('div');
		layer.classList.add('backgroundLayer');
		this.body.insertAdjacentElement('afterbegin', layer);
		this.backgroundLayer = layer;
	}

	_backgroundToggle() {
		if (!this.backgroundLayer) {
			this._addLayerToPageBody();
			this._setLayerEvents();
		}

		const isNotOpen = !this.body?.classList.contains('backgroundLayerOpen');

		if (isNotOpen) {
			$Y.notify('DUNHILL:BodyBlock.block');
			this.body?.classList.add('backgroundLayerOpen');
		} else {
			$Y.notify('DUNHILL:BodyBlock.unblock');
			this.body?.classList.remove('backgroundLayerOpen');
		}
	}

	_setLayerEvents() {
		this.backgroundLayer?.addEventListener('click', () => {
			this._backgroundToggle();
			$Y.notify('BackgroundLayer::BackgroundClicked');
		});
	}

	private _setEvents() {
		$Y.watch({'BackgroundLayer::Toggle': () => this._backgroundToggle()});
	}

	init() {
		this._setEvents();
	}
}

export default BackgroundLayerManager;
