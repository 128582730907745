export default class MPulse {
	constructor() {
		// mPulse variable
		yTos.mPulse = {
			pageGroup: ((navigation) => {
				const isF31Head = navigation.Controller !== 'ExperiencePlugin';
				if (isF31Head) {
					return navigation.Action === 'Index'
						? navigation.Controller
						: navigation.Controller + '-' + navigation.Action;
				}
				const isCustomerService = !document.location.pathname.toLowerCase().includes('experience/');
				return isCustomerService ? 'CustomerService' : 'Experience';
			})(yTos.navigation),

			referrerType: ((document) => {
				const storeHost = document.location.host;
				if (!document.referrer) {
					return 'Direct';
				}
				const referrerHost = document.referrer.replace(/.*\/\/([^/]+).*/, '$1');
				const isReferrerExternal = !referrerHost.includes(storeHost);
				return isReferrerExternal ? 'External' : 'Internal';
			})(document),
			abTest: yTos.navigation.AbTests.join('|')
		};
	}
}
