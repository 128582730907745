// @ts-nocheck

/**
 * @constant
 * @type {number}
 * @default
 */
const DEFAULT_TIMEOUT = 60000;

/**
 * Convert object to query string parameters
 *
 * @param {object} obj data
 * @return {string} query string
 */
function objectToQueryString(obj) {
	if (!obj || typeof obj !== 'object' || obj === {}) return '';

	return `?${Object.keys(obj)
		.filter((key) => obj[key] !== '')
		.map((key) => `${key}=${obj[key]}`)
		.join('&')}`;
}

/**
 * @typedef { "*GET" | "HEAD" | "POST" | "PUT" | "DELETE" | "CONNECT" | "OPTION" | "TRACE" | "PATCH" } Method
 * @typedef { "*cors" | "no-cors" | "same-origin" } Mode
 * @typedef { "*default" | "no-cache" | "reload" | "force-cache" | "only-if-cached" } Cache
 * @typedef { "*same-origin" | "include" | "omit" } Credentials
 * @typedef { "*follow" | "manual" | "error" } Redirect
 * @typedef { "*client" | "no-referrer" } ReferrerPolicy
 */

/**
 * Wrapper for standard window.fetch
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 *
 * Perks:
 * - handle error code like $.ajax
 * - support for timeout
 * - parse of object into query params
 * - support IE11
 * Defaults
 * - X-Requested-With -> XMLHttpRequest
 * @export
 * @param {string} url to call with or without query string
 * @param {object} options standard fetch options
 * @param {Method} options.method - request method.
 * @param {Mode} options.mode - request mode.
 * @param {Cache} options.cache - request cache.
 * @param {Credentials} options.credentials - request credentials.
 * @param {object} options.headers - request headers.
 * @param {Redirect} options.mode - request redirect.
 * @param {ReferrerPolicy} options.mode - request referrer.
 * @param {object} [options.data={}] - request query string parameters.
 * @param {Boolean} [options.includeCultureInfo=true] - add navigation langId and siteCode to the request query if GET.
 * @param {number} [timeout=60000] - request timeout
 * @return {Promise}
 */
export default async function fetchWrapper(url, options = {}, timeout = DEFAULT_TIMEOUT) {
	/**
	 * @constant
	 * @type {object}
	 * @default
	 */
	const DEFAULT_OPTIONS = {
		method: 'GET',
		data: {},
		headers: {},
		includeCultureInfo: true,
		abortScope: 'ALL'
	};

	const controller = new AbortController();
	const signal = controller.signal;

	options = {
		...DEFAULT_OPTIONS,
		...options,
		signal
	};

	options.headers['X-Requested-With'] = 'XMLHttpRequest';

	if (options.includeCultureInfo) {
		options.data.siteCode = yTos.navigation.SiteCode;
		options.data.langId = yTos.navigation.LangId;
	}

	url += `${objectToQueryString(options.data)}`;

	const abortEvent = {
		'DUN::FetchWrapper::Abort': () => controller.abort()
	};

	yTos.watch(abortEvent, { scope: options.abortScope });

	try {
		const response = await Promise.race([
			fetch(url, options),
			new Promise((resolve) => {
				setTimeout(resolve, timeout);
			})
		]);

		yTos.unWatch(abortEvent, { scope: options.abortScope });

		if (!response) {
			controller.abort();
			throw new Error('timeout');
		}

		if (response && !response.ok) {
			const contentType = response.headers.get('content-type');

			if (contentType?.includes('text/html')) {
				const textResponse = await response.text();
				throw new Error(textResponse);
			} else if (contentType?.includes('application/json')) {
				const jsonResponse = await response.json();
				throw new Error(JSON.stringify(jsonResponse));
			} else {
				throw new Error('error');
			}
		}

		return {
			text: () => response.text(),
			json: () => response.json(),
			arrayBuffer: () => response.arrayBuffer(),
			blob: () => response.blob(),
			formData: () => response.formData()
		};
	} catch (error) {
		return Promise.reject(error);
	}
}
