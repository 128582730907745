/* eslint-disable prettier/prettier */
import GenerateViewOptions from '../interfaces/generate-view-options';
import { loadWebComponents } from '../utils/_loadResources';

class Ajax {
	protected static _instance: Ajax;
	private _startingViewUrl: string;
	private key: string;

	static get Instance() {
		return this._instance || (this._instance = new this());
	}

	constructor() {
		this._startingViewUrl = yTos.domains.Host + '/';
	}
	
	_loadView(opts: any) {
		var defaults = {
			url: '',
			async: false,
			ajaxData: {
				isocode: yTos.navigation.AreaIsoCode
			},
			success: null,
			failure: null,
			cache: false,
		};

		opts = $.extend({}, defaults, opts || {});
		
		yTos.ajax(opts.url, {
			async: opts.async,
			data: opts.ajaxData,
			beforeSend: null,
			method: opts.method,
			id: opts.id,
			success: (viewData: any) => {
				const $viewData = $(`<div>${viewData}</div>`);
				let loadedWebComponents = $viewData.find('[data-element]');

				if (loadedWebComponents.length > 0) {
					let aArr: any[] = [];
					loadedWebComponents.each((i: any, wc: any) => {
						aArr.push(wc.getAttribute('data-element'));
					});
					loadWebComponents(aArr, false);
				}

				if (opts.success) {
					opts.success(viewData);
					return;
				}

				yTos.notify('DUNHILL::ViewLoaded::' + opts.action);
			},
			error: () => {
				opts.failure();
				yTos.notify('DUNHILL::ViewFailed::' + opts.action);
				console.error('DUNHILL::ViewFailed::' + opts.action);
			}
		});
	}

	_addCountryInfoToData(options: any) {
		if (!options.data) {
			options.data = {};
		}

		options.data.siteCode = yTos.navigation.SiteCode;
		options.data.isocode = yTos.navigation.AreaIsoCode;
	}

	generateView(controller: string, view: string, options: GenerateViewOptions = {}) {
		const defaults = {
			cache: false,
			data: null,
			method: 'GET',
			initHelpers: false,
			id: null
		};
		const path = controller + '/' + view;

		options = $.extend({}, defaults, options || {});
		this._addCountryInfoToData(options);
		this.key = options.id != null ? path + '/' + options.id : path;
		return new Promise((resolve, reject) => {
			
				this._loadView({
					url: this._startingViewUrl + path,
					action: path,
					async: options.async,
					ajaxData: options.data,
					method: options.method,
					cache: options.cache,
					failure: () => reject(new Error()),
					success: (data: any) => {
						if (options.initHelpers) {
							requestAnimationFrame(() => {
								yTos.helpers.initializeHelpers(data, {
									shouldInitializeOnlyNew: true
								});
							});
						}
						resolve({
							response: data,
							initializeHelpers: () => {
								yTos.helpers.initializeHelpers(data, {
									shouldInitializeOnlyNew: true
								});
							}
						});
					}
				});
			
		});
	}

	getViewByPlaceholderIntersection(
		controller: string,
		view: string,
		placeholderSelector: string,
		callData: any = {},
		options?: GenerateViewOptions,
		callback: Function = () => {},
		intersectionOptions: IntersectionObserverInit = { rootMargin: '200px' }
	) {
		const placeholder = document.querySelector(placeholderSelector) as HTMLElement;

		if (!placeholder) return;

		callData = Object.keys(callData).reduce((result: any, key) => {
			const attributeName = callData[key];
			const attributeValue = placeholder.dataset[attributeName] ?? attributeName;
			result[key] = attributeValue;
			return result;
		}, {});
		const observer = new IntersectionObserver(
			(entries, obs) =>
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.generateView(controller, view, { ...options, data: callData })
							.then((data: any) => {
								const template = document.createElement('template');
								template.innerHTML = data.response;
								placeholder.parentElement?.replaceChild(template.content, placeholder);
								obs.unobserve(entry.target);
							})
							.then(() => callback());
					}
				}),
			intersectionOptions
		);
		observer.observe(placeholder);
	}
}

export default Ajax.Instance;
