// @ts-nocheck
import MediaQuery from './_mediaQuery';

const CLASS_TO_MANAGE = {
	BROWSER: [
		{ isChrome: 'chrome' },
		{ isSafari: 'safari' },
		{ isApple: 'appleDevice' },
		{ isFirefox: 'firefox' },
		{ isIOS: 'ios' },
		{ isTouch: 'touchDevice' }
	],
	ORIENTATION: [{ isPortrait: 'portrait' }, { isLandscape: 'landscape' }]
};

const MEDIA_QUERIES = [
	{ Mobile: '(max-width: 767px)' },
	{ Tablet: '(min-width: 768px)' },
	{ SmallDesktop: '(min-width: 1024px)' },
	{ Desktop: '(min-width: 1280px)' },
	{ LargeDesktop: '(min-width: 1440px)' },
	{ Landscape: '(orientation: landscape)' },
	{ Portrait: '(orientation: portrait)' },
	{ MobileAndTabletRange: '(max-width: 1023px)' },
	{ TabletRange: '(min-width: 768px) and (max-width: 1023px)' },
	{ SmallDesktopRange: '(min-width: 1024px) and (max-width: 1279px)' },
	{ DesktopRange: '(min-width: 1280px) and (max-width: 1439px)' }
];

/**
 * @example
 * Devices
 * yTos.Dunhill.Devices.isSafari()
 * yTos.Dunhill.Devices.isMobile()
 * yTos.Dunhill.Devices.isTouch()
 *
 * MediaQuery
 * yTos.Dunhill.Devices.media.isMobile()
 * yTos.Dunhill.Devices.media.isSmallDesktop()
 * yTos.Dunhill.Devices.media.isLargeDesktop()
 */
class Device {
	private static _instance: Device;
	currentClass: any;
	media: {
		isMobile: Function<boolean>;
		isTablet: Function<boolean>;
		isMobileOrTablet: Function<boolean>;
		isSmallDesktop: Function<boolean>;
		isDesktop: Function<boolean>;
		isLargeDesktop: Function<boolean>;
		isLandscape: Function<boolean>;
		isPortrait: Function<boolean>;
		isTabletRange: Function<boolean>;
		isSmallDesktopRange: Function<boolean>;
		isDesktopRange: Function<boolean>;
	};
	static mqInstance: MediaQuery;
	mqInstances: never[];
	resolutions: { phones: { min: number; max: number }; tablets: { min: number; max: number } };
	lastScrollTop: number = 0;

	constructor() {
		if (!yTos.Dunhill?.Devices) {
			this.media = {};
			this.mqInstances = [];
			this.currentClass = [];

			this.resolutions = {
				phones: {
					min: 360,
					max: 600
				},
				tablets: {
					min: 600,
					max: 960
				}
			};

			this.init();
			if (!yTos.Dunhill) {
				yTos.Dunhill = {};
			}
			yTos.Dunhill.Devices = this;
		}
	}

	init() {
		let notifyFn = yTos.debounce(() => this.notifyChangeMQ());

		MEDIA_QUERIES.forEach((mq) => {
			for (let property in mq) {
				let obj = {
					name: property,
					media: mq[property],
					mqInstance: new MediaQuery(mq[property], {
						onChange: notifyFn
					})
				};

				this.mqInstances.push(obj);
				this.media['is' + property] = () => obj.mqInstance.mq.matches;
			}
		});

		this._checkClassList(CLASS_TO_MANAGE.BROWSER);
		this._checkClassList(CLASS_TO_MANAGE.ORIENTATION);
	}

	initScrollDirection() {
		// element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
		document.addEventListener(
			'scroll',
			function () {
				// or window.addEventListener("scroll"....
				var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
				if (st > this.lastScrollTop) {
					$Y.scrollingDirection = 'down';
				} else if (st < this.lastScrollTop) {
					$Y.scrollingDirection = 'up';
				} // else was horizontal scroll
				this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
			},
			false
		);
	}

	notifyChangeMQ() {
		this._checkClassList(CLASS_TO_MANAGE.ORIENTATION);

		yTos.notify('DUN::Device::changedMediaQuery', {
			matchedMedia: this.getMetchedMedia()
		});
	}

	getMetchedMedia() {
		return this.mqInstances.filter((mq) => mq.mqInstance.mq.matches);
	}

	_checkClassList(arrayObject: Object[]): void {
		let arrayClass = arrayObject.map((item) => Object.values(item)[0]);
		this._resetClassToHtml(arrayClass);

		arrayObject.forEach((item) => {
			let key = Object.keys(item)[0];

			if (typeof this[key] == 'function' && this[key]()) {
				this._addClassToHtml(item[key]);
			} else if (typeof this.media[key] == 'function' && this.media[key]()) {
				this._addClassToHtml(item[key]);
			}
		});
	}

	_resetClassToHtml(className = this.currentClass): void {
		className.forEach((key: string) => {
			document.documentElement.classList.remove(key);
		});
	}

	_addClassToHtml(className: string): void {
		if (className) {
			this.currentClass.push(className);
			document.documentElement.classList.add(className);
		}
	}

	_listAllValue(): boolean {
		return Object.getOwnPropertyNames(Device.prototype).filter((method) => {
			return method.startsWith('is');
		});
	}

	_inRange(type): boolean {
		let collection = this.resolutions[type];

		if (this.media.isPortrait()) {
			return screen.width >= collection.min && screen.width < collection.max;
		} else {
			return screen.height >= collection.min && screen.height < collection.max;
		}
	}

	/* TYPES */
	isMobile(): boolean {
		let isMobile =
			/IEMobile|Windows Phone|Lumia/i.test(navigator.userAgent) ||
			navigator.userAgent.includes('iPhone') ||
			(navigator.userAgent.includes('Android') && this._inRange('phones')) ||
			/BlackBerry|PlayBook|BB10/.test(navigator.userAgent) ||
			navigator.userAgent.includes('Mobile Safari') ||
			/webOS|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(navigator.userAgent);
		return isMobile;
	}

	isTablet(): boolean {
		let isTablet =
			/ipad|xoom|sch-i800|playbook|tablet|kindle/i.test(navigator.userAgent.toLowerCase()) ||
			(/android|android\s3\.0/i.test(navigator.userAgent.toLowerCase()) && this._inRange('tablets'));
		return isTablet;
	}

	isDesktop(): boolean {
		return !this.isMobile() && !this.isTablet();
	}

	/* BROWSERS */
	isIeEdge(): boolean {
		return navigator.userAgent.includes('Edge');
	}

	isFirefox(): boolean {
		return typeof window.InstallTrigger !== 'undefined';
	}

	isChrome(): boolean {
		return (
			(navigator.userAgent.includes('Chrome') && navigator.vendor.includes('Google Inc')) ||
			navigator.userAgent.includes('CriOS')
		);
	}

	isSafari(): boolean {
		return /^((?!chrome|CriOS|android).)*safari/i.test(navigator.userAgent);
	}

	isApple(): boolean {
		return navigator.userAgent.includes('Mac OS X');
	}

	isWebKit(): boolean {
		return this.isChrome() || this.isSafari();
	}

	/* MOBILE */
	isTouch(): boolean {
		let isTouch =
			'ontouchstart' in window ||
			navigator.MaxTouchPoints > 0 ||
			navigator.msMaxTouchPoints > 0 ||
			yTos.hasTouchEvents();
		return isTouch;
	}

	isAndroid(): boolean {
		return navigator.userAgent.includes('Android');
	}

	isIOS(): boolean {
		return yTos.isIOS();
	}

	isRealIOS() {
		return ['iPad', 'iPhone', 'iPod'].includes(navigator.platform);
	}
}

export default Device;
