// @ts-nocheck

/**
 * @example
 * new MediaQuery('(max-width: 767px)', {
 * 	onMatch: () => {
 * 		console.log('Media query is matched')
 * 	}
 * })
 *
 * @author MiceliF
 */
class MediaQuery {
	mq: MediaQueryList;
	opt: Object;
	/**
	 * @param {String} mediaQuery
	 * @param  {Object} opt
	 * @param  {Function} opt.onChange
	 * @param  {Function} opt.onMatch
	 * @param  {Function} opt.onUnMatch
	 */
	constructor(mediaQuery: string, opt: Object) {
		this.mq = window.matchMedia(mediaQuery);
		this.opt = opt;

		this.register();
	}

	register() {
		this.mq.addListener((e) => this.execute(e));
	}

	execute(e: MediaQueryListEvent) {
		this.callCb(this.opt.onChange, e);

		if (e.matches) {
			this.callCb(this.opt.onMatch, e);
		} else {
			this.callCb(this.opt.onUnMatch, e);
		}
	}

	callCb(f: any, ...args: any[]) {
		if (typeof f == 'function') {
			f(...args);
		}
	}
}

export default MediaQuery;
