class PwaManager {
	//public deferredPrompt: BeforeInstallPromptEvent | boolean = false;

	public readonly PWA_CATEGORY = 'PWA';

	constructor() {
		this.setup();
		this.init();
	}

	private trackEvent = (eventName: string) => {
		yTos.notify('DUNHILL:trackingEvent::track', {
			context: this,
			name: 'univ',
			data: {
				category: this.PWA_CATEGORY,
				event: eventName
			},
			type: 'tc'
		});
	};

	setup() {
		if (typeof yTos.pwa === 'undefined') {
			const isRunningStandalone = () => {
				/*
                 * Detecting if your app is launched from the home screen
                 * https://developers.google.com/web/fundamentals/app-install-banners/#detect-mode
                 *

                   Detect standalone mode for chrome

                 */
				// replace standalone with fullscreen or minimal-ui according to your manifest.json
				return window.matchMedia('(display-mode: standalone)').matches;
			};

			const isStandalone = () => {
				/*
                 * Detecting if your app is launched from the home screen
                 * https://developers.google.com/web/fundamentals/app-install-banners/#detect-mode
                 *

                   Detect standalone mode for safari

                 */
				// replace standalone with fullscreen or minimal-ui according to your manifest.json
				return ('standalone' in navigator && navigator['standalone']) || isRunningStandalone();
			};

			const isOnline = () => {
				return 'onLine' in navigator && navigator.onLine;
			};

			yTos.pwa = { isStandalone: isStandalone(), isOnline: isOnline() };

			if (typeof window.tc_vars == 'object' && typeof window.tc_vars.PWA_standalone == 'undefined') {
				window.tc_vars['PWA_standalone'] = isStandalone();
			}
			if (typeof window.tc_vars == 'object' && typeof window.tc_vars.PWA_isOnline == 'undefined') {
				window.tc_vars['PWA_isOnline'] = isOnline();
			}
		}
	}

	init() {
		/// FORCE UNREGISTER SERVICE WORKER TO FIX 500 on new RELEASE

		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.getRegistrations().then(function (registrations) {
				for (let registration of registrations) {
					if (registration?.active != null) {
						var sWscriptUrl = registration.active.scriptURL;
						if (sWscriptUrl.includes('/SW_DUNHILL.js?siteCode=')) {
							console.warn('|DUNHILL-SW| unregistered ' + sWscriptUrl);

							registration.unregister();
						}
					}
				}
			});

			caches.keys().then(function (names) {
				for (let name of names) {
					if (name.startsWith('dunhill-') || name.startsWith('workbox-')) {
						console.warn('|DUNHILL-SW| cleared ' + name);

						caches.delete(name);
					}
				}
			});
		}

		///

		$Y.watch({
			//yTos::Account::ReadyToLogout
			//yTos::Account::UserLoggingOut
			'yTos::Account::UserFinishedLogginOut': () => {
				// pwa hook
				if ('serviceWorker' in navigator) {
					caches.keys().then(function (names) {
						for (let name of names) {
							if (name.startsWith('dunhill-HTML') || name.startsWith('workbox-precache')) {
								console.warn('|DUNHILL-SW| HTML cleared ' + name);
								caches.delete(name);
							}
						}
					});
				}
			}
		});

		window.addEventListener('appinstalled', () => {
			// 1 Track event: The app was installed (banner or manual installation)
			this.trackEvent('appinstalled');
		});

		// If the app is installed, the ‘beforeinstallprompt’ will never be fired
		window.addEventListener('beforeinstallprompt', (e) => {
			// Prevent Chrome 67 and earlier from automatically showing the prompt
			e.preventDefault();

			// Stash the event so it can be triggered later.
			yTos.pwa.deferredPrompt = e as BeforeInstallPromptEvent;

			// 2 Track event: The web app banner has appeared
			this.trackEvent('beforeinstallprompt');

			let event = e as BeforeInstallPromptEvent;
			event.userChoice.then((result) => {
				if (result.outcome === 'accepted') {
					// 3 Track event: The web app banner was accepted
					this.trackEvent('accepted');
				} else {
					// 4 Track event: The web app banner was dismissed
					this.trackEvent('dismissed');
				}
			});
		});

		return this;
	}
}

export default PwaManager;
