/**
 * BODYBLOCK SERVICE
 * Add lock scroll service
 * --------------------------------------------------------------------
 */
import animationFrame from './reqanimframe';

const reqAnimFrame: (callback: FrameRequestCallback) => void = animationFrame.reqAnimFrame;
const cancelAnimFrame: (handle: number) => void = animationFrame.cancAnimFrame;
class BodyBlock {
	private readonly CLASS_BLOCK: string = 'bodyBlock';
	private requests: number = 0;
	private oldScroll: number;
	private scrollbarSize: number;
	private hasCaptcha: boolean = false;
	private readonly $headStylizer_BODY: JQuery = $('head style.bodyblock_body');
	private readonly $headStylizer_MARGIN: JQuery = $('head style.bodyblock_margin');
	customHeadStyle: boolean = false;

	private $window = $(window);
	private $head = $('head');
	private body = document.body;
	private html = document.documentElement;
	private $html = $('html');
	private $body = $('body');

	constructor() {
		this.init();
	}

	getStylizer(target: any): void {
		let $headStylizer = target.toLowerCase() === 'body' ? this.$headStylizer_BODY : this.$headStylizer_MARGIN;
		//@ts-ignore
		if ($headStylizer.length === 0) {
			$headStylizer = $(`<style class="bodyblock_${target}">`);
			this.$head.append($headStylizer);
		}
	}

	setStyles(): void {
		this.setStyles_MarginRight(this.scrollbarSize);
		this.customHeadStyle = true;
	}

	setStyles_MarginRight(scrollbarSize: number): void {
		const target = 'margin';
		const css = `
			html.bodyBlock body:not(.backgroundLayerOpen) { padding-right: ${scrollbarSize}px !important; }
			html.bodyBlock y-navbar { width: calc(100% - ${scrollbarSize}px) !important; }
			html.bodyBlock footer-component { width: calc(100% - ${scrollbarSize}px) !important; }
			html.bodyBlock footer-component .footer-fold { right: ${scrollbarSize}px !important; }
		`;
		this.getStylizer(target);

		$(`head style.bodyblock_${target}`).html(css);
	}

	setStyles_BODY(marginTop: number): void {
		const target = 'body';
		const css = `html.bodyBlock body{ margin-top: ${marginTop.toString()}px !important; }`;
		this.getStylizer(target);
		$(`head style.bodyblock_${target}`).html(css);
	}

	removeHeadStylizer(target?: string): void {
		if (this.customHeadStyle) {
			if (target) {
				this.$head.find(`style.bodyblock_${target}`).remove();
			} else {
				this.$head.find("style[class^='bodyblock']").remove();
			}
			this.customHeadStyle = false;
		}
	}

	block(): void {
		this.requests++;

		setTimeout(() => {
			/* get current window scroll top */
			const bTop: number = $('html, body').scrollTop() || this.$body.scrollTop();

			/* backup scrolltop data inside mainwrapper element */
			this.oldScroll = bTop;

			/* create a new css rule, used to save the mainwrapper margin top when html has th  bodyBlock class */
			this.setStyles();

			/* add the bodyBlock class to html */
			this.$html.addClass(this.CLASS_BLOCK);
			if ($('.mfp-wrap').length > 0) {
				$('.mfp-wrap').css('top', bTop);
			}
			$Y.notify('DUNHILL:BodyBlock.blocked');
		}, 1);
	}

	unblock(force: boolean = false): void {
		this.requests--;
		/* remove the bodyBlock class */
		if (this.requests <= 0 || force) {
			this.$html.addClass('unblocking');
			this.requests = 0;

			setTimeout(() => {
				this.$html.removeClass(this.CLASS_BLOCK);
				/* remove the old style */
				this.removeHeadStylizer();

				if (this.hasCaptcha) {
					$('html, body').animate({
						scrollTop: 0
					});
				} else {
					window.scrollTo(
						0,
						this.body.scrollHeight - window.screen.height - this.oldScroll < 30
							? this.body.scrollHeight
							: this.oldScroll
					);
					if (this.oldScroll == 0) {
						this.$html.removeClass('unblocking');
					} else {
						this.$window.on('scroll.bodyBlock', () => {
							this.$html.removeClass('unblocking');
							this.$window.off('.bodyBlock');
						});
					}
				}
				$Y.notify('DUNHILL:BodyBlock.unblocked');
			}, 1);
		}
	}

	unblockForce(): void {
		this.unblock(true);
	}

	private onElementHeightChange(elm: any, callback: Function) {
		if (elm.clientHeight > window.innerHeight) {
			callback();
		}
		if (elm.onElementHeightChangeTimer) {
			cancelAnimFrame(elm.onElementHeightChangeTimer);
		}

		elm.onElementHeightChangeTimer = reqAnimFrame(() => this.onElementHeightChange.call(this, elm, callback));
	}

	init(): void {
		this._initScrollbarSize();
		// eslint-disable-next-line no-undef
		const events: WatchData = {
			'DUNHILL:BodyBlock.block': this.block.bind(this),
			'DUNHILL:BodyBlock.unblock': this.unblock.bind(this),
			'DUNHILL:BodyBlock.unblock.force': this.unblockForce.bind(this),
			'DUNHILL:popup::pre.beforeOpen': () => {
				if (this.$html.hasClass(this.CLASS_BLOCK)) return;
				this.block();
			},
			'DUNHILL:popup::post.afterClose': () => {
				const isToggleMenu: boolean = $('.site-header-toggler-wrap').is(':visible');
				if (isToggleMenu && $('.site-header').hasClass('menu-open')) return;
				this.unblockForce();
			},
			'DUNHILL:popup::post.open': (obj: any) => {
				this.onElementHeightChange(obj.ctrl.wrap.find('.mfp-content')[0], this.removeHeadStylizer.bind(this, 'margin'));
			},
			'DUNHILL:popup::post.beforeClose': (obj: any) =>
				cancelAnimFrame(obj.ctrl.wrap.find('.mfp-content')[0].onElementHeightChangeTimer),
			'DUNHILL:BodyBlock.unblocked': () => (this.hasCaptcha = false)
		};

		$Y.watch(events);
	}

	/**
	 * Gets the current vertical scrollbar width size in px unit
	 * @public
	 * @returns {Number} The current vertical scrollbar width in px
	 */
	_initScrollbarSize(): void {
		// NOTE: right now this is the safest and more robust way to detect the real document scrollbar size (compatible with iOS pinch to zoom)
		// bodyscroll is gonna change overflow property anyway, so we keep this not 100% clean approach for now

		// clears possible body scroll lock state css strategies
		this.$html.removeClass(this.CLASS_BLOCK);

		// caches current html element width
		// NOTE: right now only getBoundingClientRect grant sub pixel measures, repaint would have been done anyway so...
		var docWidth = this.html.getBoundingClientRect().width;

		// fixes current body element width to avoid page jump due to overflow value change
		this.body.style.width = this.body.getBoundingClientRect().width + 'px';
		// sets overflow property to hidden
		this.html.style.overflowY = this.body.style.overflowY = 'hidden';

		// gets the actual scrollbar width comparing the cached html width to the current one with overflow hidden on
		var scrollbarWidth = this.html.getBoundingClientRect().width - docWidth;

		// cleans everything up
		this.body.style.width = this.html.style.overflowY = this.body.style.overflowY = '';

		// possibly re applies body scroll lock state css strategies
		if (this.requests > 0) {
			this.$html.addClass(this.CLASS_BLOCK);
		}

		// returns the vertical scrollbar width
		this.scrollbarSize = scrollbarWidth;
	}
}

export default BodyBlock;
