class VerifyAgeMajority {
	constructor() {
		this.init();
	}

	_verifyAge(): void {
		const $verifyAgeContainer = $('#AgeMajority_Wrapper');
		if ($verifyAgeContainer.length === 0) {
			return;
		}

		const $registrationForm = $('.registrationForm form'),
			$submitButtonContainer = $('.registrationForm .buttonsRow'),
			$submitButton = $('.registrationForm .buttonsRow .submit');

		const DisableSubmitButton = () => {
			$submitButtonContainer.addClass('disabledByAge');
			$submitButton.addClass('disabled');
			$submitButton.prop('disabled', true);
		};

		DisableSubmitButton();

		$submitButtonContainer.on('click', () => {
			yTos.helpers.UI.AjaxForm.validateForm($registrationForm);
			if ($submitButton.hasClass('disabled')) {
				$verifyAgeContainer.addClass('invalidRow');
			}
		});

		$verifyAgeContainer.on('change', '#AgeMajority_checkbox', (event: any) => {
			if (event.target.checked) {
				$submitButtonContainer.removeClass('disabledByAge');
				$submitButton.removeClass('disabled');
				$verifyAgeContainer.removeClass('invalidRow');
				$submitButton.prop('disabled', false);
			} else {
				DisableSubmitButton();
				$verifyAgeContainer.addClass('invalidRow');
			}
		});
	}

	init() {
		$Y.watch({
			'yTos::HelperLoaded::account.registrationForm': () => this._verifyAge()
		});
		return this;
	}
}

export default VerifyAgeMajority;
