/**
 * IMPORTANT
 * This enum has been created with the goal of centralize all events definition that all WebComponents can send.
 * Every events described below should be come with a description to allow other developers understand how to use it
 * to avoid inconsistency and disorganization
 */

const yTosEvents = {
	ObserveForWebComponents: () => 'DUNHILL::ObserveForWebComponents',
	/**
	 * ### Specs
	 * Disconnect web component mutation observer to improve performance
	 * @returns
	 */
	DisconnectWebComponentMutationObserve: () => 'DUNHILL::DisconnectWebComponentMutationObserve',
	/**
	 * ### Specs
	 * This event is used to load new Web Components
	 * 
	 * ### Payload to send
	 * @data This event must have a payload containing the components list to load

	 */
	LoadWebComponents: () => 'DUNHILL::LoadWebComponents',
	/**
	 * ### Specs
	 * When the viewport resize and a media query range changes, this event notify is sent
	 *
	 * ### Returns
	 * @matchedMedia The notify returns and object that contains the matched media query set in ytos-dunhill\yTos.Web.DUNHILL\Frontend\src\common\scripts\utils\_devices.ts
	 */
	ChangedMediaQuery: () => 'DUN::Device::changedMediaQuery',
	/**
	 * ### Specs
	 * When a component need to track an action done by the user, must be send this event with
	 * yTos.notify to notify the TrackingManager about the action which in its turn push into the dataLayer the event
	 *
	 * ### Payload to send
	 * @data This event must have a payload containing the event specifications with all properties defined by TrackEvent (see ../interface/track-event-payload.interface.ts)
	 */
	TrackEvents: () => 'DUNHILL::trackEvents::track',
	/**
	 * ### Specs
	 * When a product in the PLP needs to be tracked this event must be sent to allow TagCommander works
	 *
	 * ### Payload to send
	 * @data This event must have a payload containing the event specifications with all properties defined by TrackEvent (see ../interface/track-event-payload.interface.ts)
	 */
	TrackProductData: () => 'DUNHILL::TrackProductData::track',
	/**
	 * ### Specs
	 * This event notify when Brightcove is ready to be use inse y-video component
	 */
	BrightcoveReady: () => 'DUNHILL::BrightcoveReady',

	/**
	 * ### Specs
	 * This event notify when a given video is played
	 */
	VideoPlayed: (videoId: string) => `DUNHILL::Video-${videoId}::Played`,

	/**
	 * ### Specs
	 * This event notify when a given video is paused
	 */
	VideoPaused: (videoId: string) => `DUNHILL::Video-${videoId}::Paused`,

	/**
	 * ### Specs
	 * This event notify when a given video is paused
	 */
	VideoMuted: (videoId: string) => `DUNHILL::Video-${videoId}::Muted`,

	/**
	 * ### Specs
	 * This event notify when a given video is ended
	 */
	VideoEnded: (videoId: string) => `DUNHILL::Video-${videoId}::Ended`,

	/**
	 * ### Specs
	 * This event is triggered when the products in the search page are showed
	 */
	ProductsReady: () => `yTos::Search::ProductsReady`,

	/**
	 * ### Specs
	 * This event is triggered when the load more button is pressed
	 * @returns
	 */
	LoadMoreButtonPressed: () => `yTos::Search::LoadMoreButtonPressed`,

	/**
	 * ### Specs
	 * This event is triggered when the spinner in the load more must be hidden
	 */
	HideSpinner: () => `yTos::Search::HideSpinner`,

	/**
	 * ## Specs
	 * This event is triggered when the search panel is opened from the Navbar
	 * @returns
	 */
	SearchPanelLoaded: (scope?: string) => `DUNHILL::${scope ?? 'Navbar'}::SearchPanel::Loaded`,

	/**
	 * ### Specs
	 * This event is triggered when the new text must be saved in the localStorage
	 *
	 * #### Payload
	 * This event needs a payload containing the string representative the text search to save
	 */
	SaveTextSearch: (scope?: string) => `yTos::${scope ?? 'Navbar'}::SaveTextSearch`,

	/**
	 * ### Specs
	 * This event is triggered when the a recent search has been clicked and needs to be performed
	 *
	 * #### Payload
	 * The search to do
	 */
	SearchPanelDoSearch: () => `DUNHILL::SearchPanel::Search`,

	/**
	 * ### Specs
	 * This event is triggered when the FAYT is completed and the suggestion list is opened
	 */
	FaytSuggestionListOpened: () => `yTos::Search::FaytSuggestionListOpened`,

	/**
	 * ### Specs
	 * This event is triggered when there is the need to block the body
	 */
	BodyBlockBlock: () => 'DUNHILL:BodyBlock.block',

	/**
	 * ### Specs
	 * This event is triggered when there is the need to unblock the body from a body block event
	 */
	BodyBlockUnblock: () => 'DUNHILL:BodyBlock.unblock',

	/**
	 * ### Specs
	 * This event is triggered when the menu needs to be opened
	 */
	MenuOpen: () => 'DUNHILL:Menu.open',

	/**
	 * ### Specs
	 * This event is triggered when the menu needs to be closed
	 */
	MenuClose: () => 'DUNHILL:Menu.close',

	/**
	 * ### Specs
	 * This event is triggered when the shop menu is clicked (menu DOM)
	 */
	MenuShopClicked: () => 'DUNHILL:Menu.ShopClicked',

	/**
	 * ### Specs
	 * This event is triggered when the house of dunhill menu is clicked (menu DOM)
	 */
	MenuHouseOfDunhillClicked: () => 'DUNHILL:Menu.HouseOfDunhillClicked',

	/**
	 * ### Specs
	 * This event is triggered when the shop menu is clicked (navbar DOM)
	 */
	NavbarShopClicked: () => 'DUNHILL:Navbar.ShopClicked',

	/**
	 * ### Specs
	 * This event is triggered when the house of dunhill menu is clicked (navbar DOM)
	 */
	NavbarHouseOfDunhillClicked: () => 'DUNHILL:Navbar.HouseOfDunhillClicked',

	/**
	 * ### Specs
	 * This event is triggered when the Add to Wishlist Button is clicked
	 */
	AddItemToWishlistButtonClicked: () => `DUNHILL::AddItemToWishlistButton::clicked`,

	/**
	 * ### Specs
	 * This event is triggered when size isn't selected
	 */
	ShowSizeWarningMessage: () => `DUNHILL::ProductActions:ShowSizeWarningMessage`,
	/**
	 * ### Specs
	 * This event is triggered when a user successfully subscrive to the newsletter
	 */
	SubscribeNewsletterSuccess: () => `yTos::Account::SubscribeNewsletterSuccess`,
	/**
	 * ### Specs
	 * This event is triggered when there is an error when a user try to subscribe to the newsletter
	 */
	SubscribeNewsletterError: () => `yTos::Account::SubscribeNewsletterError`,
	/**
	 * ### Specs
	 * This event is triggered when an item is clicked inside Shop The Look
	 */
	ShopTheLookItemsClicked: () => `DUNHILL::Y-ShopTheLookItems::Clicked`,
	/**
	 * ### Specs
	 * This event is triggered when the PLP is updated and more items are loaded through load more button
	 */
	ItemsListUpdated: () => 'yTos::ItemsListUpdated',
	/**
	 * ### Specs
	 * This event is triggered when the user click on the button to see store availability
	 */
	ClickAndReserveInStore: () => 'DUNHILL::ClickAndReserveInStore::Clicked'
};

export default yTosEvents;
