import { loadWebComponents } from '../utils/_loadResources';

class Popups {
	protected static _instance: Popups;
	constructor() {
		this.initPanels();
	}

	initPanels() {
		/**
		 * yTos Config Extender
		 * This service class main purpose is to extend the ui configuration and
		 * some other ytos configuration
		 * --------------------------------------------------------------------
		 */

		/*
	========================
	PLATFORM POPUPS WORKFLOW
	========================

	--------------------------------
	|  OPEN POPUP EVENTS ORDER:
	--------------------------------
	1. beforeOpen
	2. elementParse
	3. beforeChange
	4. change
	5. afterChange
	6. resize [CLASSES: mfp-wrap mfp-close-btn-in mfp-auto-cursor uiLayer uiLayer-account uiLayer-newsletterLink]
	7. open [CLASSES: mfp-wrap mfp-close-btn-in mfp-auto-cursor uiLayer uiLayer-account uiLayer-newsletterLink]

	--------------------------------
	|  CLOSE POPUP EVENTS ORDER:
	--------------------------------
	1. beforeClose [CLASSES: mfp-wrap mfp-close-btn-in mfp-auto-cursor uiLayer uiLayer-account uiLayer-newsletterLink]
	2. close [CLASSES: mfp-wrap mfp-close-btn-in mfp-auto-cursor uiLayer uiLayer-account uiLayer-newsletterLink]
	3. afterClose

	--------------------------------
	|  CHANGE CONTENT POPUP EVENTS ORDER:
	--------------------------------
	1. elementParse
	2. beforeChange
	3. change
	4. afterChange

	*/

		function getContentClasses(): string[] {
			let classes = [];

			if (this.content?.hasClass('inner') && this.content.children().length) {
				let $child;
				if (this.content.children('[data-ytos-accessible-popup-content]').length) {
					$child = this.content.children('[data-ytos-accessible-popup-content]').children().not('button');
				} else {
					$child = this.content.children().not('button');
				}

				if ($child.length) {
					classes = $child[0].className.trim().replace(/\s+/, ' ').split(' ');
				}

				classes = classes
					.filter((c: any) => {
						return c.trim().length;
					})
					.map((c: any) => {
						return 'yLayer-' + c;
					});

				if (!this.st.mainClass) {
					this.st.mainClass = '';
				}

				classes = this.st.mainClass
					.split(' ')
					.concat(classes)
					.reduce((allClasses: any, current: any) => {
						let ret = [];
						current = current.trim().replace('uiLayer-', 'yLayer-');

						if (current == 'uiLayer' || current == 'yLayer-ajax') {
							return allClasses;
						}

						if (current.length && allClasses.indexOf(current) < 0) {
							ret.push(current);
						}

						return allClasses.concat(ret);
					}, []);
			}

			return classes;
		}

		yTos.DEBUG = yTos.environment !== 'prod';
		yTos.configuration.ui = {};
		yTos.configuration.ui = $.extend(yTos.configuration.ui, {
			layer: {
				removalDelay: 1100,
				fixedContentPos: false,
				fixedBgPos: false,
				callbacks: {
					beforeOpen: function () {
						$Y.notify('DUNHILL:popup::pre.beforeOpen', {
							ctrl: this
						});

						let bodyClasses = document.body.className
							.replace(/\s+/g, ' ')
							.replace(/yLayer[^\s]*/g, '')
							.trim();

						bodyClasses += ' yLayer yLayer-opening yLayer-loading';

						document.body.className = bodyClasses;

						$Y.notify('DUNHILL:popup::post.beforeOpen', {
							ctrl: this
						});

						$Y.watch({
							'DUNHILL:popup::closeIt': function () {
								this.close();
							}.bind(this)
						});
					},
					elementParse: function (item: any) {
						$Y.notify('DUNHILL:popup::pre.elementParse', {
							ctrl: this,
							item: item
						});

						if (this.st.fixedBgPos === false || (this.st.fixedBgPos === 'auto' && !this.fixedContentPos)) {
							this.bgOverlay.css({
								height: '100%'
							});
						}

						$Y.notify('DUNHILL:popup::post.elementParse', {
							ctrl: this,
							item: item
						});
					},
					beforeChange: function () {
						$Y.notify('DUNHILL:popup::pre.beforeChange', {
							ctrl: this
						});

						this.isUpdate = false;

						if (!this.content) {
							return;
						}

						/* If i'm here then this is an update */
						this.isUpdate = true;

						let bodyClasses = document.body.className
							.replace(/\s+/g, ' ')
							.replace(/yLayer[^\s]*/g, '')
							.trim();
						document.body.className =
							bodyClasses + ' yLayer yLayer-loading yLayer-opening' + (this.isAjax ? ' yLayer-ajax' : '');

						yTos.helpers.destroyPlugins(this.content);

						this.content = void 0;

						$Y.notify('DUNHILL:popup::post.beforeChange', {
							ctrl: this
						});
					},
					change: function () {
						$Y.notify('DUNHILL:popup::pre.change', {
							ctrl: this
						});

						// change the css classes of overlay, and popup
						if (typeof this.bgOverlay !== 'undefined') {
							let classes = this.bgOverlay[0].className.replace(/uiLayer[^\s]*/g, '');
							classes += ' ' + this.st.mainClass;
							classes = classes.replace(/\s+/g, ' ').trim();
							this.bgOverlay[0].className = classes;
							this.bgOverlay.addClass('uiLayer');
						}
						if (typeof this.wrap !== 'undefined') {
							let classes = this.wrap[0].className.replace(/uiLayer[^\s]*/g, '');
							classes += ' ' + this.st.mainClass;
							classes = classes.replace(/\s+/g, ' ').trim();
							this.wrap[0].className = classes;
							this.wrap.addClass('uiLayer');
						}

						$Y.notify('yTos::LayerChanged', {
							ctrl: this
						});
						$Y.notify('DUNHILL:popup::post.change', {
							ctrl: this
						});
					},
					afterChange: function () {
						$Y.notify('DUNHILL:popup::pre.afterChange', {
							ctrl: this
						});

						if (!$.contains(this.content.get(0), document.activeElement)) {
							$Y.focusElement(this.container.get(0));
						}

						$Y.notify('DUNHILL:popup::post.afterChange', {
							ctrl: this
						});

						if (this.isUpdate) {
							this.st.callbacks.open.call(this);
						}
					},
					resize: function () {
						$Y.notify('DUNHILL:popup::pre.resize', {
							ctrl: this
						});

						$Y.notify('yTos::LayerResized', {
							ctrl: this
						});

						$Y.notify('DUNHILL:popup::post.resize', {
							ctrl: this
						});
					},
					open: function () {
						$Y.notify('DUNHILL:popup::pre.open', {
							ctrl: this
						});
						if (yTos.helpers.loading === 0) {
							yTos.helpers.initializeHelpers(this.content, {
								shouldInitializeOnlyNew: true
							});
						} else {
							$Y.watchOne({
								'yTos::HelpersFinishedLoading': function () {
									yTos.helpers.initializeHelpers(this.content, {
										shouldInitializeOnlyNew: true
									});
								}.bind(this)
							});
						}

						let loadedWebComponents = this.content.find('[data-element]');

						if (loadedWebComponents.length > 0) {
							let aArr: any[] = [];
							loadedWebComponents.each((i: any, wc: any) => {
								aArr.push(wc.getAttribute('data-element'));
							});
							loadWebComponents(aArr, true);
						}

						let contentClasses = getContentClasses.call(this);
						let bodyClasses = document.body.className.replace(/yLayer[^\s]*/g, '').trim();

						if (this.isAjax) {
							bodyClasses += ' yLayer-ajax';

							yTos.watchOne({
								'DUNHILL:AjaxPopup::Ready': function () {
									contentClasses = getContentClasses.call(this);
									document.body.className =
										bodyClasses +
										' yLayer yLayer-opened ' +
										contentClasses.join(' ') +
										(this.isAjax ? ' yLayer-ajax' : '');
									$Y.notify('yTos::LayerOpened', {
										ctrl: this
									});
								}.bind(this)
							});
						} else {
							document.body.className =
								bodyClasses + ' yLayer yLayer-opened ' + contentClasses.join(' ') + (this.isAjax ? ' yLayer-ajax' : '');
							$Y.notify('yTos::LayerOpened', {
								ctrl: this
							});
						}

						$(this._lastFocusedEl).attr('aria-expanded', 'true');
						$Y.notify('DUNHILL:popup::post.open', {
							ctrl: this
						});
					},
					beforeClose: function () {
						$Y.notify('DUNHILL:popup::pre.beforeClose', {
							ctrl: this
						});
						$('body').addClass('yLayer-closing').removeClass('yLayer-opening yLayer-loading');
						$Y.notify('DUNHILL:popup::post.beforeClose', {
							ctrl: this
						});
					},
					close: function () {
						$Y.notify('DUNHILL:popup::pre.close', {
							ctrl: this
						});
						// destroy child helpers
						yTos.helpers.destroyPlugins(this.content);
						$Y.notify('DUNHILL:popup::post.close', {
							ctrl: this
						});
					},
					afterClose: function () {
						$Y.notify('DUNHILL:popup::pre.afterClose', {
							ctrl: this
						});

						document.body.className = document.body.className.replace(/yLayer[^\s]*/g, '').trim();

						$Y.notify('yTos::LayerClosed', {
							ctrl: this
						});

						$(this._lastFocusedEl).attr('aria-expanded', 'false');
						if ($('html').hasClass('accessible')) {
							$(this._lastFocusedEl).focus();
						}

						$Y.notify('DUNHILL:popup::post.afterClose', {
							ctrl: this
						});
					}
				}
			}
		});
	}
}

export default Popups;
