// js_init_tc_cookies.external_scripts :
// Key : script name
// Value: object containing script properties
//
// js_init_tc_cookies.tc_cookie_types (provided by TC cookie: TC_PRIVACY_CENTER)
//
// 1 advertising/profiling cookies
// 2 analitycs cookies
// 3 functionality cookies
// 4 techincal cookies

export default class TcCookieManager {
	private cookiesPermissionsList: Array<number> = [];

	constructor() {
		this.init();
	}

	init() {
		this._setupEvents();
		this._convertTcCookieToObject();
	}

	_convertTcCookieToObject(): void {
		let tcCookie = this._getCookie('TC_PRIVACY_CENTER');
		if (tcCookie !== undefined) {
			this.cookiesPermissionsList = tcCookie.split('%2C').map((val) => parseInt(val));
		}
	}

	_setupEvents() {
		document.addEventListener('privacy-center-accepted', () => {
			this._convertTcCookieToObject();
		});

		const cookieLink: HTMLAnchorElement | null = document.querySelector('.privacy-center-link');
		if (cookieLink) {
			cookieLink.addEventListener('click', (e: any) => {
				e.preventDefault();
				if (window.tC != undefined) {
					window.tC.privacyCenter.showPrivacyCenter();
				} else {
					console.log('Tag Commander script not present');
				}
				return false;
			});
		}
	}

	_getCookie(name: any) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop()?.split(';').shift();
	}
}
