/* eslint-disable no-unreachable */
// @ts-nocheck

/**
 * @param {object} options
 * @param {string} [options.message = 'Something went wrong']
 * @param {string} [options.level = 'Error']
 * @param {string[]} [options.tags = []]
 * @param {string} [options.stackTrace = new Error().stack]
 */

const trace = async (options) => {
	const { message = 'Something went wrong', level = 'Error', tags = [], stackTrace = new Error().stack } = options;

	tags.push('JavaScript');

	await yTos.fetch('logger/trace', {
		method: 'POST',
		includeCultureInfo: false,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'application/json',
			Accept: 'application/json'
		},
		body: JSON.stringify({
			errorLevel: level,
			errorMessage: message + '\nPage URL: ' + window.location.href + '\n',
			tags,
			stackTrace,
			countryCode: yTos.navigation.SiteCode
		})
	});

	// eslint-disable-next-line no-use-before-define
	dunhillDebug('info', `INFO: trace - ${message}`);
};

/**
 * @method yTosDebug
 * @description Attiva o meno il yTosDebug di F31
 */
const yTosDebug = () => {
	yTos.DEBUG = yTos.environment !== 'prod' || document.location.search.includes('ytosdebug=1');
};

/**
 * @method dunhillDebug
 * @description Debug custom che stampa il console.log se attivo il yTos.Debug
 */
const dunhillDebug = (level, ...args) => {
	if (typeof yTos !== 'undefined' && yTos.DEBUG) {
		// eslint-disable-next-line no-console
		console[level](...args);
	}

	if (level === 'error') {
		let error;
		if (args[0] instanceof Error) {
			error = args[0];
		} else {
			error = new Error(typeof args[0] === 'string' ? args[0] : '');
		}

		trace({
			level: 'Error',
			tags: [],
			message: error.message,
			stackTrace: error.stack
		});
	}

	return;
};

/**
 * @method dunhillDebugTable
 * @description If we are in lcl AND we have the correct local storage item set OR if
 * we are in any other env besides prod despite the local storage, then show the debug table
 */
const dunhillDebugTable = (...args) => {
	if (typeof yTos === 'undefined') {
		return;
	}

	if (yTos.environment === 'local') {
		if (localStorage.getItem('debug_tables')) {
			window.console.table(...args);
		}
	} else if (yTos.DEBUG) {
		window.console.table(...args);
	}
};

export { yTosDebug, dunhillDebug, dunhillDebugTable, trace };
